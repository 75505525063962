import React from "react";
import DialogPreview from './DialogPreview';
import Form from "Components/Core/Form/Form";
import FormElement from "Components/Core/Form/FormElement";
import FormSelectImage from "Components/Core/Form/FormSelectImage";
import 'CSS/Forum/NewPosting.css';
import 'CSS/Forum/Smilies.css';


export default function NewPosting(props) {

	const waf = props.wa.form;
	const wah = props.wa.hint;
	const was = props.wa.system;
	const wad = props.wa.dialog;
	const wam = props.wa.module;
	const wau = props.wa.utility;
	const waw = props.wa.webSocket;


	const postingId = 'posting';
	const formId = props.dialogId + '-form';
	const wafId = props.dialogId;
	const smiliesId = props.dialogId + '-smilies';

	React.useEffect(() => {
		// wau.postingRestore(wafId, props.answerTo);
	});

	wam.init(props.moduleId, {
		"onClose": () => {
			delete waf[wafId];
		}
	})

	React.useEffect(() => {
		waf.init(wafId, {
			"send": send,
			"onAfterSend": onAfterSend,
			"onBeforeSend": onBeforeSend,
		});
	});

	function onBeforeSend() {
		if (props.moduleId === 'forum' && props.answerTo === 0) {
			waf.setAddSend(wafId, 'topicid', props.topicId)
		}
		else {
			waf.setAddSend(wafId, 'topicid', 0)
		}
		// waf.set(wafId, postingId, wau.translatePosting(waf.get(wafId, postingId)));
	}

	function onAfterSend(data) {
		if (data.returncode > 0) {
			waw.send('new-posting', { "module": props.moduleId, "member": was.memberName, "memberlevel": was.memberLevel, "postingno": data.returncode });
			waf.set(wafId, postingId, '');
			wau.postingBackupRemove(wafId, props.answerTo);
			wam[props.moduleId].postings.load();
			if (was.memberLevel > 1) {
				clearPostingField();
			}
			else {
				setTimeout(() => { clearPostingField(); }, 200);
			}
			wad[props.dialogId].hide();
			if (props.moduleId !== 'forum') {
				was.loadActiveMember();
			}
			else {
				was.postingsUnread.forum = 0;
				was.makeMemberList(false);
			}
		}
		else {
			wah.addHint('Fehler beim Speichern des Postings', true);
		}
	}

	function checkPaste(event) {
		let text = event.clipboardData.getData('text');
		if (wau.checkPaste(text, (props.answerTo === 0), () => {})) {
			event.preventDefault();
		}
	}



	function clearPostingField() {
		waf.set('new-thread-short', 'new-posting-short', '');
	}

	function insertSmiley(event, key) {
		event.preventDefault();
		const target = waf[wafId].smileyTarget;
		const curPos = waf[wafId].elements[target].reference.current.selectionStart;
		const curVal = waf.get(wafId, target);
		const leftValue = curVal.substring(0, curPos);
		const rightValue = curVal.substring(curPos);
		const text = ':-' + key;
		waf.set(wafId, target, leftValue + text + rightValue);
		waf[wafId][smiliesId].hide();

	}

	function insertLink(wafId) {
		const dialogId = props.moduleId + '-add-link';
		wad[dialogId].wafId = wafId;
		wad[dialogId].show(() => { waf.focus(dialogId, 'url-title') });
	}

	function insertList(wafId) {
		const dialogId = props.moduleId + '-add-list';
		wad[dialogId].wafId = wafId;
		wad[dialogId].show(() => { waf.focus(dialogId, 'text') });
	}

	function insertText(wafId, tag) {
		const dialogId = props.moduleId + '-add-text-' + tag;
		wad[dialogId].wafId = wafId;
		wad[dialogId].show(() => { waf.focus(dialogId, 'text') });
	}

	function reset() {
		waf.set(wafId, postingId, '');
		wau.postingBackupRemove(wafId, props.answerTo);
	}

	function preview(result) {
		// console.log(result.data.content.posting);
		const previewDialog = props.id + '-preview';
		waf.set(previewDialog, 'preview-posting', wau.translatePosting(waf.get(wafId, postingId)));
		wad[previewDialog].show();
	}

	function send(event) {
		let url = 'Backend/webapp.php?module=Posting&task=SaveNewPosting&forumId=' + props.forumId;
		waf.send(wafId, url, handleResult);

	}

	function handleResult(result) {
		if (result.data.returncode !== -1) {
			waf[wafId].enableSendButton(false);
		}
		onAfterSend(result.data);
	}

	function makeSmilies(filter) {
		const smileyTags = []
		Object.entries(was.smilies).forEach(([key, value]) => {
			if (filter.length === 0 || key.includes(filter)) {
				smileyTags.push(
					<i
						key={key}
						className="smiley select-smiley "
						dangerouslySetInnerHTML={{ __html: value }}
						title={key}
						alt={key}
						onClick={(e) => insertSmiley(e, key)}
					/>);
			}
		});

		return (
			<div>
				{smileyTags}
			</div>
		)
	}

	function showSmilies(e, target) {
		waf[wafId].smileyTarget = target;
		waf[wafId][smiliesId].show(e, true)
	}

	const postingToolbar = <React.Fragment>
		<i id="iconShowSmilies" className={`bi bi-emoji-smile context-menu-icon`} title="Smilies" onClick={(e) => showSmilies(e, 'posting')}></i>
		<i className={`bi bi-type-bold context-menu-icon`} title="Fett" onClick={() => insertText(wafId, 'b')}></i>
		<i className={`bi bi-type-underline context-menu-icon`} title="Unterstrichen" onClick={() => insertText(wafId, 'u')}></i>
		<i className={`bi bi-type-italic context-menu-icon`} title="Kursiv" onClick={() => insertText(wafId, 'i')}></i>
		<i className={`bi bi-link context-menu-icon`} title="Link mit Titel einfügen" onClick={() => insertLink(wafId)}></i>
		<i className={`bi bi-list-ul context-menu-icon`} title="Liste einfügen" onClick={() => insertList(wafId)}></i>

	</React.Fragment>

	const buttons = [];
	buttons.push(<button type="button" key="reset" className="btn " onClick={reset} >Leeren</button>);
	buttons.push(<button type="button" key="preview" className="btn " onClick={preview} >Vorschau</button>);

	return (
		<React.Fragment>
			<div className="div-new-posting">
				{props.children}
				<Form id={formId}
					wa={props.wa}
					wafId={wafId}
					dialogId={props.dialogId}
					sendButtonTitle="Senden"
					addUrl={'forumId=' + props.forumId}
					module="Posting"
					sendTask="SaveNewPosting"
					classes="form-new-posting"
					buttons={buttons}
				>

					<div className="new-posting-textarea">
						<FormElement
							type="textarea"
							wa={props.wa}
							dialogId={props.dialogId}
							wafId={wafId}
							id={postingId}
							mandatory={true}
							onPaste={checkPaste}
							toolBar={postingToolbar}
							title={(props.answerTo === 0) ? props.titleText : "Ihre Antwort:"}
							fieldValue=""
							rows="8" 
							onChange={(event) => {
								wau.postingBackup(wafId, props.answerTo, event.target.value);
							}}
						/>
					</div>
				</Form>
			</div>
			<FormSelectImage
				id={smiliesId} 
				componentId={wau.getComponentId()}
				textMode={false}
				wa={props.wa}
				moduleId={props.moduleId}
				callId="iconShowSmilies"
				wafId={wafId}
				classes="select-smilies"
				title="Smilies"
				alwayShowSelection={true}
				makeContent={makeSmilies}
			/>
			<DialogPreview
				id={props.id + '-preview'}
				wa={props.wa}
				wafId={wafId}
				title="Vorschau"
				onSubmit={
					(e) => {
						if (waf.get(wafId, postingId) !== '') {
							waf[wafId].ok(e)
							wad[props.id + '-preview'].hide();								
						}
						else {
							e.preventDefault();
							wah.addHint('Leere Nachricht, senden nicht möglich.', true);							
						}
					}
				}
			/>

		</React.Fragment>
	);
}
